import React from "react";
import logo from "../images/landing-page/logo-che-color.svg";

const Header = () => {
    return (
        <header>
            <div className="header">
                <div className="header__logo d-flex justify-content-center mx-auto">
                    <a href="/">
                        <img className="logo__image img-fluid" src={logo} alt="Logo" />
                    </a>

                </div>
                <div className="header__phone-numbers d-flex justify-content-center">
                    <div className="header__phone-number text-center">
                        <h4 className="phone-number__title color-grey-100 d-inline me-3"> Call us at  </h4>
                        <p className="phone-number__number b3 color-grey-300 d-inline"> <a href="tel:(512) 840-1273" className="color-grey-300"> (512) 840-1273 </a> </p> 
                        <span className="b3 color-grey-300 me-2 ms-2"> or </span>
                        <p className="phone-number__number b3 color-grey-300 d-inline"> <a href="tel:(972) 432-7783" className="color-grey-300"> (972) 432-7783 </a> </p>
                        <span className="b3 color-white ms-3 phone-number--valid"> Valid for all Texas! </span>
                    </div>
                    {/* <div className="header__phone-number text-center">
                        <h4 className="phone-number__title color-grey-100 d-inline"> Dallas Phone: </h4>
                        <p className="phone-number__number b3 color-grey-300 d-inline"> <a href="tel:(972) 432-7783" className="color-grey-300"> (972) 432-7783 </a> </p>
                    </div> */}
                </div>
            </div>
        </header>
    );
}

export default Header;