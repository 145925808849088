import React, { useState, useEffect } from "react";
import axios from "axios";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";

const FormComponent = () => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    phone_number: "",
    email: "",
    preferred_call_time: "",
    interested_in: [],
    utm_source: "",
    utm_medium: "",
    utm_campaign: "",
    ref: "",
  });

  // let params = new URLSearchParams(window.location.search);

  // let ref = params.get("ref");

  // console.log(ref); // logs: busadD2402

  const handleInputChange = (e) => {
    const { name, value, checked, type } = e.target;

    if (type === "checkbox" && name === "interested_in") {
      const updatedInterests = checked
        ? [...formData.interested_in, value]
        : formData.interested_in.filter((item) => item !== value);

      setFormData((prevState) => ({
        ...prevState,
        interested_in: updatedInterests,
      }));
    } else if (
      type === "checkbox" &&
      (name === "accepted_terms" ||
        name === "consent_text_messages" ||
        name === "consent_calls")
    ) {
      setFormData((prevState) => ({
        ...prevState,
        [name]: checked,
      }));
    } else if (name === "phone_number") {
      const formattedPhoneNumber = value.replace(/\D/g, "").slice(0, 10);
      const formattedValue = formattedPhoneNumber.replace(
        /(\d{3})(\d{3})(\d{4})/,
        "($1) $2-$3"
      );

      setFormData((prevState) => ({
        ...prevState,
        [name]: formattedValue,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const validatePhoneNumber = (phoneNumber) => {
    return /^\d{3}-\d{3}-\d{4}$/.test(phoneNumber);
  };

  useEffect(() => {
    // Access UTM parameters from the URL
    const params = new URLSearchParams(window.location.search);
    const utmSource = params.get("utm_source") || "";
    const utmMedium = params.get("utm_medium") || "";
    const utmCampaign = params.get("utm_campaign") || "";
    const ref = params.get("ref") || "";

    setFormData((prevState) => ({
      ...prevState,
      utm_source: utmSource,
      utm_medium: utmMedium,
      utm_campaign: utmCampaign,
      ref: ref,
    }));
  }, []);

  const validateForm = () => {
    // List of required fields
    const requiredFields = ["name", "phone_number", "email", "interested_in"];

    // Check if any required field is empty
    const emptyField = requiredFields.find((field) => !formData[field]);

    if (emptyField) {
      return `Please fill in the ${emptyField} field`;
    }

    // Check if the 'interested_in' field is an empty array
    if (formData.interested_in.length === 0) {
      return "Please select at least one checkbox for 'Interested In'";
    }

    // Validate phone number
    const phoneNumberRegex = /^\(\d{3}\) \d{3}-\d{4}$/; // Updated regex for formatted US phone number
    if (!phoneNumberRegex.test(formData.phone_number)) {
      return "Please enter a valid phone number";
    }

    // Validate email (simple regex for demonstration)
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      return "Please enter a valid email address";
    }

    // Check if the 'accepted_terms' field is not checked
    if (!formData.accepted_terms) {
      return "Please accept the terms and conditions to proceed.";
    }

    // Check if the 'consent_text_messages' field is not checked
    if (!formData.consent_text_messages) {
      return "Please consent to receive text messages to proceed.";
    }

    // Check if the 'consent_calls' field is not checked
    if (!formData.consent_calls) {
      return "Please consent to receive calls to proceed.";
    }

    // If all required fields are filled, 'interested_in' is not an empty array, and the phone number and email are valid, return null
    return null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate the form
    const errorMessage = validateForm();
    if (errorMessage) {
      document.querySelector(".error-text").textContent = errorMessage;
      return; // If the form is not valid, return and don't disable the button
    }

    setIsSubmitting(true); // Disable the button

    // // Check if any required field is empty
    // const requiredFields = [
    //   "name",
    //   "phone_number",
    //   "email",
    //   "interested_in",
    //   "accepted_terms",
    //   "consent_text_messages",
    //   "consent_calls",
    // ];
    // const isEmptyField = requiredFields.some((field) => !formData[field]);

    // if (!formData.accepted_terms) {
    //   document.querySelector(".error-text").textContent =
    //     "Please accept the terms and conditions to proceed.";
    //   return;
    // }

    // if (!formData.consent_text_messages || !formData.consent_calls) {
    //   document.querySelector(".error-text").textContent =
    //     "Please accept the text messages and calls consent to proceed.";
    //   return;
    // }

    // if (isEmptyField) {
    //   document.querySelector(".error-text").textContent =
    //     "Please fill in all required fields";
    //   return;
    // } else if (formData.interested_in.length === 0) {
    //   document.querySelector(".error-text").textContent =
    //     "Please select at least one checkbox for 'Interested In'";

    //   return;
    // } else {
    //   document.querySelector(".error-text").textContent = "";
    // }

    // // Validate phone number
    // const phoneNumberRegex = /^\(\d{3}\) \d{3}-\d{4}$/; // Updated regex for formatted US phone number
    // if (!phoneNumberRegex.test(formData.phone_number)) {
    //   document.querySelector(".error-text").textContent =
    //     "Please enter a valid phone number";
    //   return;
    // } else {
    //   document.querySelector(".error-text").textContent = "";
    // }

    // // Validate email (simple regex for demonstration)
    // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // if (!emailRegex.test(formData.email)) {
    //   document.querySelector(".error-text").textContent =
    //     "Please enter a valid email address";
    //   return;
    // } else {
    //   document.querySelector(".error-text").textContent = "";
    // }

    try {
      const response = await axios.get(
        `https://backend.checlinic.org/check-phone/${formData.phone_number}`
      );

      // Check if phone number exists in the database
      if (response.data.exists) {
        window.dataLayer.push({
          event: "formSubmission",
          formType: "LeadForm",
          success: "duplicate",
        });
        // console.log(window.dataLayer);
      } else {
        window.dataLayer.push({
          event: "formSubmission",
          formType: "LeadForm",
          success: "unique",
        });
        // console.log(window.dataLayer);
      }

      // Proceed with form submission
      const submitResponse = await axios.post(
        "https://backend.checlinic.org/submit-form",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Success:", submitResponse.data);
      navigate("/thank-you");
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsSubmitting(false); // Enable the button again
    }

    // axios.post('https://backend.checlinic.org/submit-form', formData, {
    //     headers: {
    //         'Content-Type': 'application/json',
    //     }
    // })
    //     .then(response => {
    //         console.log('Success:', response.data);
    //         navigate('/thank-you');
    //     })
    //     .catch(error => {
    //         console.error('Error:', error);
    //     });
  };

  return (
    <>
      <h1 className="mb-4"> Ready for PrEP? Let CHE Guide Your Journey! </h1>
      <div className="masthead-form-container mb-4">
        <div className="d-flex flex-column flex-lg-row w-100">
          <FloatingLabel
            controlId="floatingInput"
            label="Name *"
            className="mb-3 w-100 me-3"
          >
            <Form.Control
              type="text"
              placeholder="Your Name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
            />
          </FloatingLabel>

          <FloatingLabel
            controlId="floatingPassword"
            label="Phone number *"
            className="mb-3 w-100 me-3"
          >
            <Form.Control
              type="tel"
              placeholder="Phone Number"
              pattern="[0-9]{10}"
              title="Please enter a valid phone number"
              name="phone_number"
              value={formData.phone_number}
              onChange={handleInputChange}
            />
          </FloatingLabel>

          <FloatingLabel
            controlId="floatingInput"
            label="Email"
            className="mb-3 w-100"
          >
            <Form.Control
              type="email"
              placeholder="Your Email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
            />
          </FloatingLabel>
        </div>

        <h4 className="color-grey-500 mb-3">I am interested in</h4>
        <div className="d-flex flex-column flex-lg-row mb-3">
          <Form.Check
            type="checkbox"
            id="testingCheckbox"
            label="HIV / STI Testing"
            name="interested_in"
            value="HIV / STI Testing"
            checked={formData.interested_in.includes("HIV / STI Testing")}
            onChange={handleInputChange}
            className="color-grey-400 me-5"
          />

          <Form.Check
            type="checkbox"
            id="prepCheckbox"
            label="PrEP"
            name="interested_in"
            value="PrEP (Oral/Injectables)"
            checked={formData.interested_in.includes("PrEP (Oral/Injectables)")}
            onChange={handleInputChange}
            className="color-grey-400 me-5"
          />
          <Form.Check
            type="checkbox"
            id="treatmentCheckbox"
            label="HIV Treatment"
            name="interested_in"
            value="HIV Treatment"
            checked={formData.interested_in.includes("HIV Treatment")}
            onChange={handleInputChange}
            className="color-grey-400 me-5"
          />

          <Form.Check
            type="checkbox"
            id="otherCheckbox"
            label="Other"
            name="interested_in"
            value="Other"
            checked={formData.interested_in.includes("Other")}
            onChange={handleInputChange}
            className="color-grey-400"
          />
        </div>

        {/* <div className="d-flex mb-4">
          <Form.Check
            type="checkbox"
            id="treatmentCheckbox"
            label="HIV Treatment"
            name="interested_in"
            value="HIV Treatment"
            checked={formData.interested_in.includes("HIV Treatment")}
            onChange={handleInputChange}
            className="color-grey-400 me-5"
          />

          <Form.Check
            type="checkbox"
            id="otherCheckbox"
            label="Other"
            name="interested_in"
            value="Other"
            checked={formData.interested_in.includes("Other")}
            onChange={handleInputChange}
            className="color-grey-400"
          />
        </div> */}
        <Form.Check
          type="checkbox"
          id="acceptedtermsCheckbox"
          name="accepted_terms"
          checked={formData.accepted_terms}
          onChange={handleInputChange}
          className="color-grey-400 mb-3"
          label={
            <span>
              I have read the&nbsp;
              <a
                href="https://www.checlinic.org/copy-of-privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                terms and conditions
              </a>
              &nbsp;and{" "}
              <a
                href="https://www.checlinic.org/services-7"
                target="_blank"
                rel="noopener noreferrer"
              >
                privacy policy
              </a>
            </span>
          }
        />

        <p className="b3 color-grey-400 mb-3">
          {" "}
          “Keeping your personal identifiable information in mind, select the
          option(s) below which fit your preference.” Consent to Receive
          Communications*{" "}
        </p>

        <Form.Check
          type="checkbox"
          id="consentCheckbox"
          name="consent_text_messages"
          checked={formData.consent_text_messages}
          onChange={handleInputChange}
          className="color-grey-400 mb-3"
          label="I give my consent to receive text messages"
        />

        <Form.Check
          type="checkbox"
          id="consentCallsCheckbox"
          name="consent_calls"
          checked={formData.consent_calls}
          onChange={handleInputChange}
          className="color-grey-400 mb-3"
          label="I give my consent to receive calls"
        />

        <p className="label-caption color-grey-400 mb-3">
          {" "}
          By consenting to receive text messages & entering your phone number,
          you are agreeing to allow Center for Health Empowerment to send you
          text messages. Text HELP for help and reply STOP to cancel. Message
          frequency varies and message and data rates apply. Carriers are not
          liable for delayed or undelivered messages.{" "}
        </p>
        <button
          className="primary-button mb-3"
          type="submit"
          onClick={handleSubmit}
          disabled={isSubmitting}
        >
          Submit
        </button>

        <p className="error-text b3 color-red-600"> </p>
      </div>
      <div className="masthead-info-container">
        <p className="b2 color-grey-900">
          Center for Health Empowerment is committed to provide everyone the HIV
          prevention and treatment services at low to no cost. We deal with the
          insurance companies, benefits, pharmacies and the manufacturers to
          make it easy for you so that you can lead an empowered and pleasure
          positive life. Our team of community advocates is here for you.
        </p>
      </div>
    </>
  );
};

export default FormComponent;
