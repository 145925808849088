import React from "react";
import Layout from "../Layout";

const TermsConditionsPage = () => {
  return (
    <Layout title="Terms & Conditions">
      <div id="terms-conditions-page">
        <div className="container">
          <h1 className="color-grey-900 mb-3 text-center">
            Terms & Conditions
          </h1>
          <div className="terms-container">
            <p className="b2 color-grey-900 mb-4">
              We appreciate your visit to Center for Health Empowerment website.
              Your use of our website is contingent on your agreement to comply
              with the following terms and conditions (referred to as the
              &quot;Terms of Service&quot;). We urge you to carefully review
              this document before proceeding to access or utilize the CENTER
              FOR HEALTH EMPOWERMENT website. Your access or use of the website
              implies your consent to abide by these Terms of Service. If you
              disagree with these terms, please refrain from accessing or using
              the website or submitting any data/information.
            </p>
            <h4 className="color-grey-900 mb-4">
              Modification of These Terms of Service
            </h4>
            <p className="b2 color-grey-900 mb-4">
              CENTER FOR HEALTH EMPOWERMENT maintains the right to alter the
              terms, conditions, and notices at its discretion, and such
              revisions will take effect immediately upon their posting. It is
              your responsibility to routinely check these Terms of Service,
              along with any additional terms or notices published on the CENTER
              FOR HEALTH EMPOWERMENT website. Continued access to this website
              will be considered as your unequivocal acceptance of the revised
              agreement.
            </p>
            <h4 className="color-grey-900 mb-4"> Privacy </h4>
            <p className="b2 color-grey-900 mb-4">
              To comprehend our policies concerning the use and disclosure of
              your personal information, please refer to our Privacy Policy,
              which also governs your interactions with CENTER FOR HEALTH
              EMPOWERMENT.
            </p>
            <h4 className="color-grey-900 mb-4"> Electronic Communications </h4>
            <p className="b2 color-grey-900 mb-4">
              By visiting CENTER FOR HEALTH EMPOWERMENT or sending us emails,
              you acknowledge that you are engaging in electronic communication
              with us, agreeing to receive communications from us electronically
              or through other available means such as telephone or fax.
              Furthermore, you acknowledge that any agreements, notices,
              disclosures, and other communications provided to you
              electronically satisfy the legal requirement for written
              communication. By using our website and services, you consent to
              receive text messages, calls, or automated messages and calls from
              the electronic medical record system.
            </p>
            <h4 className="color-grey-900 mb-4"> SMS (Text Messages) </h4>
            <p className="b2 color-grey-900 mb-4">
              CENTER FOR HEALTH EMPOWERMENT sends SMS messages for appointment
              reminders, lab results, and general health discussions. Message
              frequency may vary, and{" "}
              <span className="font-italic">
                {" "}
                standard message and data rates may apply. You can reply with
                &quot;STOP&quot; to opt out or &quot;HELP&quot; for assistance.
                Please note that carriers cannot be held responsible for delayed
                or undelivered messages.{" "}
              </span>
            </p>
            <p className="b2 color-grey-900 mb-4">
              By entering your phone number, you are agreeing to allow CENTER
              FOR HEALTH EMPOWERMENT to send you text messages. Message
              frequency varies. Message and data rates may apply. Reply STOP to
              cancel. <span className="font-italic"> Text HELP for help. </span>  Carriers are not liable for delayed or
              undelivered messages.
            </p>
            <h4 className="color-grey-900 mb-4"> Copyright and Licensing </h4>
            <p className="b2 color-grey-900 mb-4">
              The entire content of this website, including but not limited to
              text, graphics, logos, button icons, images, audio clips, digital
              downloads, data compilations, and software, belongs to CENTER FOR
              HEALTH EMPOWERMENT, its content providers, or its clients. This
              content is protected by domestic and international copyright and
              trademark laws. Without prior express written consent from CENTER
              FOR HEALTH EMPOWERMENT, you may not modify, copy, reproduce,
              republish, upload, post, transmit, or distribute any portion of
              the website&#39;s content. You may, however, print or download
              parts of the material from various sections of this website
              strictly for your personal, non-commercial use, provided that you
              agree not to alter or remove any copyright or proprietary notices.
              If you believe that any content on our website infringes on the
              copyright of another party, please contact us immediately at
              www.musiccityprep.org to report such infringement.
            </p>
            <p className="b2 color-grey-900 mb-4">
              Submission of files, including but not limited to resumes, emails,
              flash movies, images, logos, audio loops, and other software,
              signifies your consent to grant CENTER FOR HEALTH EMPOWERMENT a
              non-exclusive, royalty-free, worldwide, sublicensable, perpetual
              license to reproduce, distribute, transmit, modify, adapt,
              sublicense, and publicly display such submissions. You also grant
              CENTER FOR HEALTH EMPOWERMENT the right to use your name in
              connection with all advertising, marketing, and promotional
              materials related to these submissions.
            </p>
            <h4 className="color-grey-900 mb-4">
              {" "}
              License and Website Access{" "}
            </h4>
            <p className="b2 color-grey-900 mb-4">
              CENTER FOR HEALTH EMPOWERMENT grants you a limited license to
              access and make personal use of this website, with the exception
              of downloading for page caching purposes or modifying it, or any
              part of it, without the express written consent of CENTER FOR
              HEALTH EMPOWERMENT. This website, or any part of it, may not be
              reproduced, duplicated, adapted, copied, sold, resold,
              transmitted, or otherwise exploited for any commercial purpose
              without the express written consent of CENTER FOR HEALTH
              EMPOWERMENT. You may not frame or employ any framing techniques to
              enclose any trademarks, logos, or other proprietary information
              (including images, text, flash movies, page layout, or form)
              belonging to CENTER FOR HEALTH EMPOWERMENT without the express
              written consent of CENTER FOR HEALTH EMPOWERMENT. You may not use
              META tags or any other &quot;hidden text&quot; utilizing
              www.musiccityprep.org, CENTER FOR HEALTH EMPOWERMENT, or any other
              derivative of our domain or company name without the express
              written consent of CENTER FOR HEALTH EMPOWERMENT. You are granted
              a limited, revocable, and non-exclusive right to create a
              hyperlink to the home page of CENTER FOR HEALTH EMPOWERMENT, as
              long as the link does not present any false, misleading,
              derogatory, or otherwise offensive material.
            </p>
            <p className="b2 color-grey-900 mb-4">
              It is understood that CENTER FOR HEALTH EMPOWERMENT cannot
              guarantee or warrant that files available for download through the
              CENTER FOR HEALTH EMPOWERMENT website will be free from infection
              or viruses, worms, Trojan horses, or other code with contaminating
              or destructive properties. It is your responsibility to implement
              sufficient safeguards and procedures to ensure that any files
              obtained from CENTER FOR HEALTH EMPOWERMENT are free from such
              contaminants.
            </p>
            <h4 className="color-grey-900 mb-4">
              Disclaimer of Warranty and Limitation of Liability
            </h4>
            <p className="b2 color-grey-900 mb-4">
              THIS WEBSITE AND ASSOCIATED INFORMATION ARE PROVIDED BY CENTER FOR
              HEALTH EMPOWERMENT ON AN &quot;AS IS&quot; AND &quot;AS
              AVAILABLE&quot; BASIS. CENTER FOR HEALTH EMPOWERMENT MAKES NO
              EXPRESS OR IMPLIED WARRANTIES, REPRESENTATIONS, OR ENDORSEMENTS OF
              ANY KIND, REGARDING THE OPERATION OF THIS WEBSITE OR THE
              INFORMATION, CONTENT, MATERIALS, OR PRODUCTS INCLUDED HEREIN. YOU
              EXPRESSLY ACKNOWLEDGE THAT YOUR USE OF THIS WEBSITE IS AT YOUR OWN
              RISK.
            </p>
            <p className="b2 color-grey-900 mb-4">
              TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, CENTER FOR
              HEALTH EMPOWERMENT DISCLAIMS ALL WARRANTIES, INCLUDING IMPLIED
              WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
              PURPOSE. CENTER FOR HEALTH EMPOWERMENT DOES NOT WARRANT THAT THIS
              WEBSITE, ITS SERVERS, OR E-MAILS SENT FROM THIRD PARTY PARTNERS
              ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. CENTER FOR HEALTH
              EMPOWERMENT WILL NOT BE LIABLE FOR ANY DAMAGES ARISING FROM THE
              USE OF THIS SITE, INCLUDING BUT NOT LIMITED TO DIRECT, INDIRECT,
              INCIDENTAL, PUNITIVE, AND CONSEQUENTIAL DAMAGES.
            </p>
            <p className="b2 color-grey-900 mb-4">
              ALL OUR PARTNERS MAKES REASONABLE EFFORTS TO PROVIDE ACCURATE AND
              UP-TO-DATE INFORMATION ON THIS WEBSITE. HOWEVER, CENTER FOR HEALTH
              EMPOWERMENT OR ITS PARTNERS ASSUMES NO LIABILITY OR RESPONSIBILITY
              FOR TYPOGRAPHICAL ERRORS OR OTHER OMISSIONS IN THE CONTENT OF THIS
              SITE. IN THE EVENT THAT A PRODUCT IS INCORRECTLY LISTED WITH AN
              INACCURATE PRICE OR OTHER INCORRECT INFORMATION, CENTER FOR HEALTH
              EMPOWERMENT OR ITS PARTNERS RETAINS THE RIGHT TO DECLINE OR CANCEL
              ANY ORDERS FOR SUCH PRODUCTS. IF A PRODUCT OFFERED BY CENTER FOR
              HEALTH EMPOWERMENT OR ITS PARTNERS DOES NOT MEET THE DESCRIPTION,
              YOUR SOLE REMEDY IS TO RETURN IT TO CENTER FOR HEALTH EMPOWERMENT
              FOR A REFUND. ALL POSTAGE COSTS FOR RETURNING A PRODUCT SHALL BE
              BORNE BY THE BUYER.
            </p>
            <p className="b2 color-grey-900 mb-4">
              CERTAIN STATE LAWS MAY NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES
              OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS
              APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS, OR
              LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL
              RIGHTS.
            </p>
            <h4 className="color-grey-900 mb-4"> Third-Party Links </h4>
            <p className="b2 color-grey-900">
              Linked websites are beyond the control of CENTER FOR HEALTH
              EMPOWERMENT, and MUSIC
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default TermsConditionsPage;
