import React from "react";
import image from "../images/landing-page/d_section2.webp";
import imageMobile from "../images/landing-page/m_section2.webp";
import personIcon from "../images/landing-page/s_icon-person.svg";
import teleIcon from "../images/landing-page/s_icon-telehealth.svg";
import insuranceIcon from "../images/landing-page/s_icon-insurance.svg";

const WhoAreWe = () => {
    return (
        <section className="who-are-we-section">
            <div className="container">
                <div className="row equal-cols gx-0 flex-wrap-reverse">
                    <div className="col-12 col-md-6">
                        <picture>
                            <source srcSet={image} media="(min-width: 768px)" />
                            <img src={imageMobile} alt="" className="img-fluid w-100" />
                        </picture>
                        <div className="belief-quote-container">
                            <p className="label-quote-lg color-white">
                                “CHE believes in providing care that is welcoming, affirming, and empowering”
                            </p>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="who-are-we-info-container">
                            <h1 className="color-grey-900 mb-4"> Who Are We? </h1>
                            <p className="b2 color-grey-500 mb-3"> CHE is a non-profit with the mission of providing access to high-quality health and wellness services for all. </p>
                            <p className="b2 color-grey-500 mb-5"> Our services are centered around sexual health and HIV/STD prevention. </p>
                            <div className="who-are-we-cards-container">
                                <div className="who-are-we-card d-flex align-items-center mb-3">
                                    <img src={personIcon} alt="" className="me-3" />
                                    <h4 className="color-grey-500">
                                        In-person visits
                                    </h4>
                                </div>
                                <div className="who-are-we-card d-flex align-items-center mb-3">
                                    <img src={teleIcon} alt="" className="me-3" />
                                    <h4 className="color-grey-500">
                                        Telehealth visits
                                    </h4>
                                </div>
                                <div className="who-are-we-card d-flex align-items-center">
                                    <img src={insuranceIcon} alt="" className="me-3" />
                                    <h4 className="color-grey-500">
                                        Accepts most insurance plans
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default WhoAreWe;