import React from "react";
import ThankYouImage from "../images/thank-you-page/d_masthead-image.webp";
import Icon from "../images/thank-you-page/icon-victory.svg";

const ThankYou = () => {
    return (
        <div className="thank-you-container position-relative">
            <div className="thank-you-pattern-container position-absolute">

            </div>
            <div className="container position-relative">
                <div className="row align-items-center flex-wrap-reverse">
                    <div className="col-12 col-md-6">
                        <img src={ThankYouImage} alt="" className="img-fluid position-relative thank-you-image"/>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="d-flex justify-content-center mb-4">
                            <img src={Icon} alt="" />
                        </div>
                        <h2 className="color-lucid-pink-600 text-center mb-4">Thank You For Taking The First Step!</h2>
                        <p className="b1 text-center color-migol-blue-600"> We appreciate your commitment to your health. Expect a phone call with next steps soon. For any questions, we’re here to help. Stay healthy! </p>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ThankYou;