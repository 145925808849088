import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import HomePage from './components/pages/HomePage';
import ThankYouPage from './components/pages/ThankYouPage';
import SignupPage from './components/pages/SignupPage';
import LeadsPage from './components/pages/LeadsPage';
import AuthenticationPage from './components/pages/AuthenticationPage';
import PrivacyPolicyPage from './components/pages/PrivacyPolicyPage';
import TermsConditionsPage from './components/pages/TermsConditionsPage';

const AppRouter = () => {
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [isInitialCheckDone, setInitialCheckDone] = useState(false);

  useEffect(() => {
    const storedLoggedIn = localStorage.getItem('isLoggedIn');
    if (storedLoggedIn) {
      setLoggedIn(true);
    }
    setInitialCheckDone(true);
  }, []);

  const PrivateRoute = ({ component: Component, ...rest }) => {
    const navigate = useNavigate();

    useEffect(() => {
      if (!isLoggedIn && isInitialCheckDone) {
        navigate('/authentication');
      }
    }, [isLoggedIn, navigate, isInitialCheckDone]);

    if (!isLoggedIn && isInitialCheckDone) {
      return null;
    }

    return <Component {...rest} />;
  };

  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/thank-you" element={<ThankYouPage />} />
        <Route path="/sign-up" element={<SignupPage />} />
        <Route
          path="/authentication"
          element={<AuthenticationPage setLoggedIn={setLoggedIn} />}
        />
        <Route
          path="/leads"
          element={<PrivateRoute component={LeadsPage} />}
        />
        <Route
          path="/privacy-policy"
          element={<PrivacyPolicyPage />}
        />
        <Route
          path="/terms-and-conditions"
          element={<TermsConditionsPage />}
        />
      </Routes>
    </Router>
  );
};

export default AppRouter;