import React from "react";
import axios from "axios";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';

const SignupForm = () => {
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        const form = e.target;
        const username = form.elements.username.value;
        const password = form.elements.password.value;
        const role = form.elements.role.value;

        try {
            const response = await axios.post("https://backend.checlinic.org/sign-up", {
                username,
                password,
                role
            });

            console.log(response.data); // Do something with the response

            // Redirect to a success page or any other page
            navigate("/success");
        } catch (error) {
            console.error(error);
            // Handle the error
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <FloatingLabel
                controlId="floatingInput"
                label="Username"
                className="mb-3"
            >
                <Form.Control
                    type="text"
                    placeholder="Enter username"
                    name="username"
                />
            </FloatingLabel>
            <FloatingLabel
                controlId="floatingInput"
                label="Password"
                className="mb-3"
            >
                <Form.Control
                    type="text"
                    placeholder="Enter password"
                    name="password"
                />
            </FloatingLabel>

            <Form.Select
                aria-label="Role"
                className="mb-3"
                name="role"
            >
                <option>Role</option>
                <option value="superadmin">Superadmin</option>
                <option value="admin">Admin</option>
                <option value="client">Client</option>
            </Form.Select>

            <button className="primary-button" type="submit">
                Signup
            </button>
        </form>
    );
};

export default SignupForm;