import React, { useEffect, useState } from "react";
import axios from "axios";
import Layout from "../Layout";
import { saveAs } from "file-saver";
import { Pagination } from "react-bootstrap";

const LeadsPage = () => {
  const [leadsData, setLeadsData] = useState([]);

  useEffect(() => {
    axios
      .get("https://backend.checlinic.org/get-form-entries")
      .then((response) => {
        setLeadsData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching leads data:", error);
      });
  }, []);

  const formattedLeadsData = leadsData.map((lead) => {
    const date = new Date(lead.timestamp);

    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };

    const formattedDate = date.toLocaleDateString("en-US", options);

    return {
      ...lead,
      formattedTimestamp: formattedDate,
    };
  });

  const reversedLeadsData = [...formattedLeadsData].reverse();

  // Pagination

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleDownloadCSV = () => {
    const csvData = formattedLeadsData.map((lead, index) => {
      const leid = `${20000001 + index}`;
      const euid = `${20000001 + index}`;

      const tags = [];

      // if (lead.preferred_call_time) {
      //   tags.push(`TIME_${lead.preferred_call_time}`);
      // }

      if (lead.interested_in !== null) {
        const interests = Array.isArray(lead.interested_in)
          ? lead.interested_in.map((interest) => `INT_${interest}`)
          : JSON.parse(lead.interested_in).map((interest) => `INT_${interest}`);
        tags.push(...interests);
      }

      if (lead.utm_source) {
        tags.push(`SRC_${lead.utm_source}`);
      }
  
      if (lead.ref) {
        tags.push(`REF_${lead.ref}`);
      }
  
      const [firstName, lastName] = lead.name.split(" ");
      const formattedLastName = lastName ? lastName : "";
  
      const tagsString = `"${tags
        .map((tag) => `""${tag.replace(/"/g, '""')}""`)
        .join(", ")}"`;
  
      return `${leid},${euid},${firstName},${formattedLastName},${lead.email},${lead.phone_number},"${lead.formattedTimestamp}",${tagsString},,,,,,,,`;
    });

    const csvContent = [
      "LEID,EUID,First Name,Last Name,Email Address,Phone Number,Time Stamp,TAGS,Ref,Birthday,MEMBER_RATING,OPTIN_TIME,OPTIN_IP,CONFIRM_TIME,CONFIRM_IP,LATITUDE,LONGITUDE,GMTOFF,DSTOFF,TIMEZONE,CC,REGION,LAST_CHANGED,NOTES",
    ]
      .concat(csvData)
      .join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
    saveAs(blob, "che_leads.csv");
  };

  return (
    <Layout title="Leads">
      <div className="leads-page">
        <div className="leads-masthead-container mb-5">
          <div className="container">
            <div className="d-flex justify-content-between">
              <h1 className="color-grey-900"> Leads </h1>
              <button className="primary-button" onClick={handleDownloadCSV}>
                Download CSV
              </button>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="leads-data-container section-spacing">
            <div className="leads-data-head">
              <div className="row">
                <div className="col">
                  <p className="label-label color-grey-400">Name and Email</p>
                </div>
                <div className="col">
                  <p className="label-label color-grey-400">Phone number</p>
                </div>
                <div className="col">
                  <p className="label-label color-grey-400">Time Stamp</p>
                </div>
                {/* <div className="col">
                  <p className="label-label color-grey-400">
                    Preferred time to call
                  </p>
                </div> */}
                <div className="col">
                  <p className="label-label color-grey-400">
                    I am interested in
                  </p>
                </div>
                <div className="col">
                  <p className="label-label color-grey-400">UTM Source</p>
                </div>
                <div className="col">
                  <p className="label-label color-grey-400">Ref</p>
                </div>
              </div>
            </div>
            <div className="leads-data">
              {/* {getCurrentPageData(currentPage).map((lead, index) => {})} */}
              {reversedLeadsData
                .slice(
                  (currentPage - 1) * itemsPerPage,
                  currentPage * itemsPerPage
                )
                .map((lead, index) => (
                  <div className="row" key={index}>
                    <div className="col">
                      <p className="b2 color-grey-900"> {lead.name} </p>
                      <p className="b3 color-grey-400"> {lead.email} </p>
                    </div>
                    <div className="col">
                      <p className="b3 color-grey-900"> {lead.phone_number} </p>
                    </div>
                    <div className="col">
                      <p className="b3 color-grey-900">
                        {" "}
                        {lead.formattedTimestamp}{" "}
                      </p>
                    </div>
                    {/* <div className="col">
                    <div className="chip">
                      <p className="b3 color-grey-600">
                        {lead.preferred_call_time}
                      </p>
                    </div>
                  </div> */}
                    <div className="col">
                      {lead.interested_in !== null &&
                        (Array.isArray(lead.interested_in)
                          ? lead.interested_in.map((interest, index) => (
                              <div className="chip-secondary mb-2" key={index}>
                                <p className="b3 color-grey-600">{interest}</p>
                              </div>
                            ))
                          : JSON.parse(lead.interested_in).map(
                              (interest, index) => (
                                <div
                                  className="chip-secondary mb-2"
                                  key={index}
                                >
                                  <p className="b3 color-grey-600">
                                    {interest}
                                  </p>
                                </div>
                              )
                            ))}
                    </div>
                    <div className="col">
                      <div className="chip">
                        <p className="b3 color-grey-600">{lead.utm_source}</p>
                      </div>
                    </div>
                    <div className="col">
                      {" "}
                      <p className="b3 color-grey-600">{lead.ref}</p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>

        <Pagination className="leads-pagination">
          {[...Array(Math.ceil(reversedLeadsData.length / itemsPerPage))].map(
            (e, i) => (
              <Pagination.Item
                key={i}
                active={i + 1 === currentPage}
                onClick={() => handlePageChange(i + 1)}
              >
                {i + 1}
              </Pagination.Item>
            )
          )}
        </Pagination>
      </div>
    </Layout>
  );
};

export default LeadsPage;
