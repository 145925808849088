import React from "react";
import FormComponent from "./FormComponent";
import MastheadImagePrimary from "../images/landing-page/new_section/d_masthead_group.webp";
import MastheadImageSecondary from "../images/landing-page/new_section/d_what_is_prep.webp";
import MastheadPrimaryIcon from "../images/landing-page/new_section/d_scribble_1.svg";
import OptionIconOne from "../images/landing-page/new_section/s_icon_1.svg";
import OptionIconTwo from "../images/landing-page/new_section/s_icon_2.svg";
import OptionIconThree from "../images/landing-page/new_section/s_icon_3.svg";
import OptionIconFour from "../images/landing-page/new_section/s_icon_4.svg";
import OptionIconFive from "../images/landing-page/new_section/s_icon_5.svg";

const Masthead = () => {
  return (
    <>
      <section className="masthead-section position-relative">
        <div className="masthead-pattern-containe position-absolute"></div>
        <div className="masthead-section-primary container">
          <div className="row mb-4 mb-md-0 flex-wrap-reverse gx-0 align-items-center">
            <div className="col-12 col-md-6">
              <img src={MastheadImagePrimary} alt="" className="img-fluid" />
            </div>
            <div className="col-12 col-md-6">
              <img
                src={MastheadPrimaryIcon}
                alt=""
                className="d-flex justify-content-start"
              />
              <h1 className="color-migol-blue-600 mb-4">PrEP</h1>
              <h2 className="color-grey-600 mb-4">
                Empower your sex life & your Community
              </h2>
              <p className="b1 color-grey-400 mb-5">
                Get the daily pill that’s 99% effective at preventing HIV
              </p>
              <a href="#form-section" className="primary-button">
                Book an Appointment
              </a>
            </div>
          </div>
        </div>
        <div className="masthead-section-secondary container">
          <div className="row align-items-center">
            <div className="col-12 col-md-6">
              <h1 className="mb-4"> What is PrEP? </h1>
              <p className="b1">
                Pre-Exposure Prophylaxis or PrEP is a once daily pill that
                reduces the risk of HIV infection in Negative-Tested patients.
                PrEP doesn't prevent other STI's.
              </p>
            </div>
            <div className="col-12 col-md-6">
              <img src={MastheadImageSecondary} alt="" className="img-fluid" />
            </div>
          </div>
          <div>
            <h2 className="mb-4"> PrEP can be a good option if: </h2>
            <div className="row">
              <div className="col-12 col-md-6 mb-4">
                <div className="d-flex">
                  <img src={OptionIconOne} alt="" className="me-4" />
                  <p className="b1">
                    You’re in an ongoing sexual relationship with a partner
                    living with HIV.
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-6 mb-4">
                <div className="d-flex">
                  <img src={OptionIconTwo} alt="" className="me-4" />
                  <p className="b1">
                    You’re in a new sexual relationship but not yet aware of
                    your sexual partner's HIV status and not using protection.
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-6 mb-4">
                <div className="d-flex">
                  <img src={OptionIconThree} alt="" className="me-4" />
                  <p className="b1">
                    You’re having multiple casual sexual encounters and
                    protection use is not always guaranteed.
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-6 mb-4">
                <div className="d-flex">
                  <img src={OptionIconFour} alt="" className="me-4" />
                  <p className="b1">
                    You’re not using protection with partners who are at high
                    risk of HIV infection.
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-6 mb-4">
                <div className="d-flex">
                  <img src={OptionIconFive} alt="" className="me-4" />
                  <p className="b1">
                    You’ve shared injecting equipment or have been in a
                    treatment program for injecting drug use.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="container position-relative">
          <div className="masthead-info-container">
            <p className="b2 color-grey-900">
              Center for Health Empowerment is committed to provide everyone the
              HIV prevention and treatment services at low to no cost. We deal
              with the insurance companies, benefits, pharmacies and the
              manufacturers to make it easy for you so that you can lead an
              empowered and pleasure positive life. Our team of community
              advocates is here for you.
            </p>
          </div>
        </div> */}
      </section>
    </>
  );
};

export default Masthead;
