import React from "react";
import Layout from "../Layout";
import Masthead from "../Masthead";
import WhoAreWe from "../WhoAreWe";
import OurServices from "../OurServices";
import FormSection from "../FormSection";
const Homepage = () => {
  return (
    <Layout title="CHE Clinic | Get PrEP Online | PrEP Access Made Easy">
      <Masthead />
      <div className="two-sections-container">
        <WhoAreWe />
        <OurServices />
      </div>
      <FormSection />
    </Layout>
  );
};

export default Homepage;
