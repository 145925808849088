import React from "react";
import prepImage from "../images/landing-page/d_prep.webp";
import pepImage from "../images/landing-page/d_pep.webp";
import stiImage from "../images/landing-page/d_sti.webp";
import hivImage from "../images/landing-page/d_hiv_testing.webp";
import prepImageMobile from "../images/landing-page/m_prep.webp";
import pepImageMobile from "../images/landing-page/m_pep.webp";
import stiImageMobile from "../images/landing-page/m_sti.webp";
import hivImageMobile from "../images/landing-page/m_hiv_testing.webp";


const OurServices = () => {
    return (
        <section className="our-services-section">
            <h1 className="color-grey-900 text-center">
                Our Services
            </h1>
            <div className="container">
                <div className="our-services-cards-container">
                    <div className="row">
                        <div className="col-12 col-md-6 mb-4">
                            <div className="our-service-card">
                                <h2 className="color-black mb-4"> STI Testing & Treatment </h2>
                                <div className="d-block d-md-flex flex-wrap flex-md-nowrap">
                                    <img src={stiImage} alt="" className="me-3 img-fluid d-none d-md-block" />
                                    <img src={stiImageMobile} alt="" className="img-fluid mb-3 w-100 d-block d-md-none" />
                                    <p className="b3"> If you're sexually active, we recommend getting tested for STIs every 3-6 months. This is especially important since many STIs don't usually cause symptoms. </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="our-service-card">
                                <h2 className="color-black mb-4"> HIV Testing & Care </h2>
                                <div className="d-block d-md-flex flex-wrap flex-md-nowrap">
                                    <img src={hivImage} alt="" className="me-3 img-fluid d-none d-md-block" />
                                    <img src={hivImageMobile} alt="" className="img-fluid mb-3 w-100 d-block d-md-none" />
                                    <p className="b3"> At CHE, we offer HIV testing and sexual health counseling to all our clients. It is recommended to keep getting tested regular to be aware of your HIV status. </p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </section>
    )
};

export default OurServices;