import React from "react";
import logo from "../images/landing-page/logo-che-color.svg";
import facebookIcon from "../images/landing-page/icon-facebook.svg";
import twitterIcon from "../images/landing-page/x-logo.svg";
import instagramIcon from "../images/landing-page/icon-instagram.svg";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__logo d-flex justify-content-center mx-auto mb-5">
          <img className="logo__image img-fluid" src={logo} alt="Logo" />
        </div>
        <hr />
        <div className="footer-info-container row gx-5">
          <div className="col-12 col-md mb-4">
            <h4 className="color-grey-100 mb-2"> South Austin </h4>
            <p className="b3 color-grey-400 mb-2"> (512) 840-1273 </p>
            <p className="b3 color-grey-400 mb-2">
              2312 Western Trails Blvd., 
              <br></br>
              Suite #103, Austin, TX 78745
            </p>
          </div>
          <div className="col-12 col-md mb-4">
            <h4 className="color-grey-100 mb-2"> Dallas </h4>
            <p className="b3 color-grey-400 mb-2"> (972) 432-7783 </p>
            <p className="b3 color-grey-400 mb-2">
              {" "}
              951 York Drive 
              <br></br>
              Suite #102 DeSoto, TX 75115
            </p>
          </div>
          <div className="col-12 col-md mb-4">
            {/* <h4 className="color-grey-100 mb-2"> Lancaster </h4>
            <p className="b3 color-grey-400 mb-2">
              {" "}
              2500 W Pleasant Run Rd, Suite #220, Lancaster, TX 75146{" "}
            </p> */}
          </div>
          <div className="col-12 col-md-2">
            <div class="d-block d-md-none mb-4">
              <a
                href="/terms-and-conditions"
                className="label-label color-grey-400 d-block"
              >
                {" "}
                Terms & Conditions{" "}
              </a>
              <a
                href="/privacy-policy"
                className="label-label color-grey-400 d-block"
              >
                {" "}
                Privacy Policy{" "}
              </a>
            </div>
            <h4 className="color-grey-100 mb-4"> Follow us on: </h4>
            <div className="social-media-container d-flex mb-0 mb-md-4">
              <a href="https://www.facebook.com/checlinic/" className="me-3">
                <img src={facebookIcon} alt="" />
              </a>
              <a
                href="https://www.instagram.com/checlinic/?hl=es"
                className="me-3"
              >
                <img src={instagramIcon} alt="" />
              </a>
              <a href="https://twitter.com/checlinic">
                <img src={twitterIcon} alt="" />
              </a>
            </div>
            <div class="d-none d-md-block">
              <a
                href="/terms-and-conditions"
                className="label-label color-grey-400 d-block"
              >
                {" "}
                Terms & Conditions{" "}
              </a>
              <a
                href="/privacy-policy"
                className="label-label color-grey-400 d-block"
              >
                {" "}
                Privacy Policy{" "}
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
