import React, { useEffect } from "react";
import "../scss/app.scss";
import Header from "./Header";
import Footer from "./Footer";

const Layout = ({ children, title }) => {
  useEffect(() => {
    document.title = title;
  }, [title]);
  return (
    <>
      <Header />
      <main>{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
