import React from "react";
import FormComponent from "./FormComponent";

const FormSection = () => {
  return (
    <section id="form-section">
      <div className="container">
        <FormComponent />
      </div>
    </section>
  );
};

export default FormSection;
