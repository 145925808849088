import React from "react";
import Layout from "../Layout";

const PrivacyPolicyPage = () => {
  return (
    <Layout title="Privacy Policy">
      <div id="privacy-policy-page">
        <div className="container">
          <h1 className="color-grey-900 mb-3 text-center">Privacy Policy</h1>
          <div className="terms-container">
            <p className="b2 color-grey-900 mb-4">
              In order to provide services to our clients, CENTER FOR HEALTH
              EMPOWERMENT may collect a wide variety of data from our clients.
              CENTER FOR HEALTH EMPOWERMENT is committed and take every possible
              precaution to protect this data and the confidentiality of our
              clients. We also use this data to constantly learn and improve the
              way we operate and provide services to our clients. Here we
              describe how your data is protected and used by CENTER FOR HEALTH
              EMPOWERMENT.
            </p>
            <p className="b2 color-grey-900 mb-4">
              Your data is hosted in secured servers and only a restricted
              number of team members can access it. This may include your
              navigators, providers or the data team. All personnel have
              dedicated username and password that is used to log in to our
              electronic medical record systems
            </p>
            <p className="b2 color-grey-900 mb-4">
              CENTER FOR HEALTH EMPOWERMENT is deeply committed to safeguarding
              your privacy. This Privacy Policy has been established to outline
              the processing and utilization of your personal information, and
              we are dedicated to upholding the principles of this policy. Your
              use of www.checlinic.org or any of its sub-domains signifies your
              acceptance of the practices defined in this Privacy Policy.
            </p>
            <h4 className="color-grey-900 mb-4">
              **What Information Does CENTER FOR HEALTH EMPOWERMENT Collect and
              How is it Utilized?**
            </h4>
            <p className="b2 color-grey-900">
              Every device connected to the Internet possesses a domain name
              (e.g., google.com) and an IP Address (e.g., 192.164.13.145). When
              a visitor accesses a page on the CENTER FOR HEALTH EMPOWERMENT
              website, our Web Servers automatically identify and log the HTTP
              request made to our Web Server. This information does not reveal
              any personal details about you. The only data we automatically
              collect and log includes:
            </p>
            <ul className="b2 color-grey-900 mb-4" type="disc">
              <li> The IP Address of the site that may have referred you. </li>
              <li>Your IP Address.</li>
              <li>
                The web page you may have linked to us from, if applicable.
              </li>
              <li>
                The Product Identifier for your browser version and make (e.g.,
                Internet Explorer 4.5).
              </li>
              <li>
                The operating system platform you may be using (e.g., Macintosh
                or Windows).
              </li>
              <li>
                Search words or terms transmitted from a search engine (e.g.,
                Google, Yahoo, or Lycos).
              </li>
            </ul>
            <p className="b2 color-grey-900 mb-4">
              The data described above is standard and widely collected by
              virtually all web servers on the Internet today. This information
              serves a dual purpose. Firstly, CENTER FOR HEALTH EMPOWERMENT
              finds it advantageous to analyze visitor traffic in the aggregate
              to ensure maximum compatibility for different browsers and
              operating systems that visit our site. Secondly, it helps us
              enhance our online presence by strategically analyzing visitation
              patterns, referring URLs, and search engine terms.
            </p>
            <h4 className="color-grey-900 mb-4">**What About Cookies?**</h4>
            <p className="b2 color-grey-900 mb-4">
              Cookies are small pieces of data transferred to your
              computer&#39;s hard drive from our Web Server via your web
              browser. Cookies cannot read data from your hard disk or read
              other cookies created by different sites. The CENTER FOR HEALTH
              EMPOWERMENT website employs cookies to provide personalization
              features for our visitors. For example, we use cookies to remember
              settings (such as background music selections or wallpaper
              preferences) that you may have configured on our website.
            </p>
            <p className="b2 color-grey-900 mb-4">
              You have the option to accept or decline cookies by adjusting your
              browser settings. Most browsers have a help feature that can guide
              you on how to prevent your browser from accepting all cookies or
              notify you when a cookie is being sent. Refusing cookies may
              impact your experience on our website and others, causing some
              features to not function as intended.
            </p>
            <h4 className="color-grey-900 mb-4">
              **What Other Information Does CENTER FOR HEALTH EMPOWERMENT
              Request?**
            </h4>
            <p className="b2 color-grey-900 mb-4">
              We may request your email address or mailing address for survey
              purposes, adding you to our mailing list, or contacting you for
              additional information upon your request. Whenever we seek a
              visitor&#39;s identity, we will clearly state the purpose of the
              inquiry before collecting the information. We adhere to a strict
              &quot;No Spam&quot; policy, which means we do not intend to sell,
              rent, or share your email address with third parties without your
              consent.
            </p>
            <p className="b2 color-grey-900 mb-4">
              Additionally, we will not send you unsolicited emails. If you
              subscribe to our mailing list, you will receive updates, news,
              portfolio additions, and information about new products or
              services. You have the option to unsubscribe from our mailing list
              at any time, which will halt further email communication. For
              direct communication with a member of the CENTER FOR HEALTH
              EMPOWERMENT staff, specific information is required to route your
              request to the appropriate contact within our organization.
            </p>
            <h4 className="color-grey-900 mb-4">
              **Will CENTER FOR HEALTH EMPOWERMENT Share Information with Third
              Parties?**
            </h4>
            <p className="b2 color-grey-900 mb-4">
              CENTER FOR HEALTH EMPOWERMENT will disclose your personal
              information or any log file data when required by law or in good
              faith to:
            </p>
            <ul className="b2 color-grey-900 mb-4" type="disc">
              <li> Comply with legal processes or the law&#39;s mandates. </li>
              <li>
                Safeguard and defend the rights or property of CENTER FOR HEALTH
                EMPOWERMENT, or the visitors of our website.
              </li>
              <li>
                Identify individuals potentially violating the law, legal
                notice, or the rights of third parties.
              </li>
              <li>
                Cooperate with investigations into alleged unlawful activities.
              </li>
            </ul>
            <p className="b2 color-grey-900 mb-4">
              We maintain reasonable precautions to secure the information
              provided to us. CENTER FOR HEALTH EMPOWERMENT reserves the right
              to transfer information related to the sale of all or part of our
              capital stock or assets to a third party.
            </p>
            <h4 className="color-grey-900 mb-4"> **SMS (Text Messages)** </h4>
            <p className="b2 color-grey-900 mb-4">
              No information is shared or sold to third parties, including
              opt-in consent data, mobile numbers, and personnel information
              data.
            </p>
            <p className="b2 color-grey-900 mb-4">
              Moreover, we are not responsible for security breaches or the
              actions of third parties receiving information. CENTER FOR HEALTH
              EMPOWERMENT may include links to various other websites on the
              internet. We are not responsible for the privacy policies or
              information management of these websites, so we advise you to
              review their privacy policies.
            </p>
            <p className="b2 color-grey-900 mb-4">
              Further, At CENTER FOR HEALTH EMPOWERMENT, we strive to constantly
              improve ourselves and learn from our data. Data driven decision
              making is one of the core values of our organization. However,
              during these learning processes, we ensure that your data is
              protected and your confidentiality is maintained. None of the data
              analysis and learning processes we undertake involve extracting
              any personal identifiers from our databases.
            </p>
            <p className="b2 color-grey-900">
              These are some of the ways we may use your data:
            </p>
            <ol className="b2 color-grey-900 mb-4" type="1">
              <li> Create business dashboards to monitor our performance </li>
              <li>
                Using data to conduct secondary data analysis and research, as
                approved by registered institutional review boards
              </li>
              <li>
                Learn who is responding better to what kind of outreach
                initiatives so that we can optimize our outreach activities
              </li>
              <li>
                Create predictive models to understand care interruptions and
                implement interventions to prevent that from happening
              </li>
            </ol>
            <p className="b2 color-grey-900 mb-5">
              Any alterations to this Privacy Policy will be posted on this
              page, ensuring you are always aware of the information we collect,
              how we use it, and when we disclose it.
            </p>
            <p className="b2 color-grey-900">
              Copyright © 2019, CENTER FOR HEALTH EMPOWERMENT. All Rights
              Reserved.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PrivacyPolicyPage;
