import React, { useState } from 'react';
import SignupForm from "../SignupForm";

const AuthenticationPage = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isLoggedIn, setLoggedIn] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
    };

    return (
        <div>
            <div className="authentication-page d-flex justify-content-center align-items-center">
                <div className="authentication-form-container">
                    <h1 className="color-dark-grey-900 mb-4"> Signup </h1>
                    <SignupForm />
                </div>

            </div>

        </div>
    );
};

export default AuthenticationPage;