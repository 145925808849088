import React from "react";
import axios from "axios";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';

const LoginForm = ({ setLoggedIn }) => {
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = e.target;
    const username = form.elements.username.value;
    const password = form.elements.password.value;

    try {
      const response = await axios.post("https://backend.checlinic.org/login", {
        username,
        password
      });

      if (response.data.success) {
        setLoggedIn(true);
        localStorage.setItem('isLoggedIn', true);
        navigate(response.data.redirect);
        console.log("Successfully Logged in");
      } else {
        console.error(response.data.error);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <FloatingLabel
          controlId="floatingUsername"
          label="Username"
          className="mb-3"
        >
          <Form.Control
            type="text"
            placeholder="Enter username"
            name="username"
          />
        </FloatingLabel>
        <FloatingLabel
          controlId="floatingPassword"
          label="Password"
          className="mb-3"
        >
          <Form.Control
            type="password"
            placeholder="Enter password"
            name="password"
          />
        </FloatingLabel>
        <button className="primary-button" type="submit">
          Login
        </button>
      </form>
    </>
  );
};

export default LoginForm;