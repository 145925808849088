import React from 'react';
import Layout from '../Layout';
import ThankYou from '../ThankYou';

const ThankYouPage = () => {
    return (
        <Layout title="Thank You">
            <ThankYou />
        </Layout>
    )
};

export default ThankYouPage;